import { getByDisplayValue } from '@testing-library/react';
import styles from './ServiceTerms.module.css';

const ServiceTerms = (props) => {
    const acceptanceHandler = () => {
        props.onAcceptTerms(true);
    };

    return (
      <div className={styles.classes}>
        <header className={styles.header}>
          <h2>Terms and conditions of service</h2>
        </header>
        {/*
        <ol>
          <li key="1">
            <strong>Introduction</strong>
            <br />
            Welcome to exambridge.com ("the Website"). By accessing or using the
            Website, you agree to comply with these Terms and Conditions. If you
            do not agree with any part of these terms, please do not use the
            Website.
          </li>
          <li key="2">
            <strong>Purpose of the Website</strong>
            <br />
            The Website is designed to help students prepare for examinations by
            providing questions and answers for various subjects taught in
            pre-degree schools. The platform is free to use and is intended for
            students, teachers, and parents.
          </li>
          <li key="3">
            <strong>User Eligibility</strong>
            <br />
            The Website is available to all individuals, including students,
            teachers, and parents. By using the Website, you confirm that you
            are accessing the content for educational purposes only.
          </li>
          <li key="4">
            <strong>Acceptable Use</strong>
            <br />
            Users must adhere to the following guidelines:
            <ol className={styles.sub - ol}>
              <li key="a">Use the Website solely for educational purposes</li>
              <li key="b">
                Do not engage in activities that disrupt or harm the Website
              </li>
              <li key="c">
                Do not distribute or reproduce Website content for commercial
                purposes
              </li>
              <li key="d">
                Do not upload, share, or distribute offensive, inappropriate, or
                copyrighted content
              </li>
            </ol>
          </li>
          <li key="5">
            <strong>Intellectual Property Rights</strong>
            <br />
            All content provided on the Website, including but not limited to
            questions, answers, study materials, and design elements, are owned
            by exambridge.com or its content providers. Unauthorized use,
            reproduction, or distribution of this content is prohibited
          </li>
          <li key="6">
            <strong>Disclaimer of Warranties</strong>
            <br />
            The Website provides educational materials on an "as is" basis.
            While efforts are made to ensure accuracy, the Website does not
            guarantee the completeness, reliability, or correctness of any
            content. Users should verify information before relying on it for
            academic purposes.
          </li>
          <li key="7">
            <strong>Limitation of Liability</strong>
            <br />
            exambridge.com is not responsible for any direct, indirect,
            incidental, or consequential damages resulting from the use or
            inability to use the Website. Users access and use the content at
            their own risk.
          </li>
          <li key="8">
            <strong>Privacy Policy</strong>
            <br />
            The Website may collect limited user information for functionality
            purposes. User data will not be shared or sold to third parties. For
            more details, please refer to our Privacy Policy
          </li>
          <li key="9">
            <strong>Changes to Terms and Conditions</strong>
            <br />
            We reserve the right to update these Terms and Conditions at any
            time. Continued use of the Website after changes are posted
            constitutes acceptance of the revised terms.
          </li>
          <li key="10">
            <strong>Contact Information</strong>
            <br />
            For any questions or concerns regarding these Terms and Conditions,
            please contact us at
            <br />
            [Contact Information].
          </li>
        </ol>
        */}

        <p>
          <strong>Introduction</strong>
          <br />
          Welcome to exambridge.com ("the Website"). By accessing or using the
          Website, you agree to comply with these Terms and Conditions. If you
          do not agree with any part of these terms, please do not use the
          Website.
          <br />
          <strong>Purpose of the Website</strong>
          <br />
          The Website is designed to help students prepare for examinations by
          providing questions and answers for various subjects taught in
          pre-degree schools. The platform is free to use and is intended for
          students, teachers, and parents.
          <br />
          <strong>User Eligibility</strong>
          <br />
          The Website is available to all individuals, including students,
          teachers, and parents. By using the Website, you confirm that you are
          accessing the content for educational purposes only.
          <br />
          <strong>Acceptable Use</strong>
          <br />
          Users must adhere to the following guidelines:
          <br />
          <span style={{ display: "block", paddingLeft: "0.5rem" }}>
            (a). Use the Website solely for educational purposes
          </span>
          <span style={{ display: "block", paddingLeft: "0.5rem" }}>
            (b). Do not engage in activities that disrupt or harm the Website
          </span>
          <span style={{ display: "block", paddingLeft: "0.5rem" }}>
            (c). Do not distribute or reproduce Website content for commercial
            purposes
          </span>
          <span style={{ display: "block", paddingLeft: "0.5rem" }}>
            (d). Do not upload, share, or distribute offensive, inappropriate,
            or copyrighted content
          </span><br />
          <strong>Intellectual Property Rights</strong>
          <br />
          All content provided on the Website, including but not limited to
          questions, answers, study materials, and design elements, are owned by
          exambridge.com or its content providers. Unauthorized use,
          reproduction, or distribution of this content is prohibited
          <br />
          <strong>Disclaimer of Warranties</strong>
          <br />
          The Website provides educational materials on an "as is" basis. While
          efforts are made to ensure accuracy, the Website does not guarantee
          the completeness, reliability, or correctness of any content. Users
          should verify information before relying on it for academic purposes.
          <br />
          <strong>Limitation of Liability</strong>
          <br />
          exambridge.com is not responsible for any direct, indirect,
          incidental, or consequential damages resulting from the use or
          inability to use the Website. Users access and use the content at
          their own risk.
          <br />
          <strong>Privacy Policy</strong>
          <br />
          The Website may collect limited user information for functionality
          purposes. User data will not be shared or sold to third parties. For
          more details, please refer to our Privacy Policy
          <br />
          <strong>Changes to Terms and Conditions</strong>
          <br />
          We reserve the right to update these Terms and Conditions at any time.
          Continued use of the Website after changes are posted constitutes
          acceptance of the revised terms.
          <br />
          <strong>Contact Information</strong>
          <br />
          For any questions or concerns regarding these Terms and Conditions,
          please contact us at
          <br />
          info@exambridge.com
        </p>
        <div className={styles.btnDiv}>
          <button
            className={styles.bgGreen}
            type="button"
            onClick={acceptanceHandler}
          >
            Accept
          </button>
          <button
            className={styles.bgRed}
            type="button"
            onClick={() => window.location.reload()}
          >
            Reject
          </button>
        </div>
      </div>
    );

};

export default ServiceTerms;