import { useState, useEffect, useContext } from "react";
import LoginContext from "../../../store/login/LoginContext";
import styles from "./ContactUs.module.css"
import axios from "axios";

const regex_email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailValidation = (value) =>
  regex_email.test(value.trim()) && value.trim().length < 65;

const regex = /^[0-9a-zA-Z .,-;']{10,250}$/;
const messageValidation = (value) =>
  !value ? false : regex.test(value.trim());

const ContactUs = (props) => {
    const loginCtx = useContext(LoginContext);
    const [email, setEmail] = useState("");
    const [emailHasError, setEmailHasError] = useState();
    const [message, setMessage] = useState("");
    const [messageHasError, setMessageHasError] = useState();

    const submitHandler = async () => {
      if (!message) {
          alert("Error detected", "All fields must be filled correctly");
          return;
      }
      if (email && !emailValidation(email)) {
        setEmailHasError(true);
        return;
      } else {
        setEmailHasError(false);
      }

      if (!messageValidation(message)) {
        setMessageHasError(true);
        return;
      } else {
        setMessageHasError(false);
      }

      console.log("message data:", { isLoggedIn: loginCtx.isLoggedIn, email, message });
      try {
        const response = await axios.post(
          "php/sign_in/submit_message.php",
          { isLoggedIn: loginCtx.isLoggedIn, email, message }
        );
        console.log("response data", response.data);
        if (response.data == "Access denied" || response.data == 0) {
          alert(
            "Error connecting to server. Check your internet connection or retry later"
          );
          return;
        }
        if (response.data == 1) {                
            alert("Your message has been submitted");
            if (!loginCtx.isLoggedIn) props.onCancel();
            else props.onSubmit()
        } else {
            alert("Error submiting your message!! Please retry");
        }
          
      } catch (error) {
          alert("Error submiting your message!! Please retry")
      }
    };
    
    return (
      <div className={styles.classes}>
        <header>
          <h2>Contact us </h2>
          <strong>Please fill the form below</strong>
        </header>
        <ol>
          <li>
            <strong>Email: </strong> (This is optional). If you want us to
            contact you by email, please enter a valid email
          </li>
          <li>
            <strong>Message: </strong>
            Length between 10-250 characters. Only alphanumerics , . - ; are
            allowed
          </li>
        </ol>
        <div className={styles.inputView}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            placeholder="Enter your email"
            maxLength={24}
            required
            autoComplete="off"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
          />
          {emailHasError && (
            <span className={styles.errorText}>Your email is invalid</span>
          )}
        </div>
        <div>
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            rows={10}
            cols={60}
            maxLength={450}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {messageHasError && <span>Your message is invalid</span>}
        </div>
        <div className={styles.btnDiv}>
          <button
            className={styles.bgGreen}
            type="button"
            onClick={submitHandler}
          >
            Submit
          </button>
          <button
            className={styles.bgRed}
            type="button"
            onClick={props.onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
};

export default ContactUs;
