import "../../index.css";

const Header = () => {
  return (
    <header className="main-header">
      <img
        className="main-header-img"
        src="./logo.png"
        alt="Exambridge Logo"
      />
      <h1>Ace Your Exams with Free Practice Questions!</h1>
    </header>
  );
};

export default Header;
