import styles from './ShareUs.module.css';

const ShareUs = (props) => {
  return (
    <div className={styles.classes}>
      <p>Help others discover Exambridge — share on social media!</p>
      <div style={{ display: "flex", marginLeft: "2rem" }}>
        <a
          href="https://www.facebook.com/sharer/sharer.php?u=https://exambridge.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-facebook fa-2x"></i>
        </a>
        <a
          href="https://twitter.com/intent/tweet?url=https://exambridge.com&text=Check%20out%20this%20awesome%20exam%20prep%20site!"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-twitter fa-2x"></i>
        </a>
        <a
          href="https://wa.me/?text=Check%20out%20this%20awesome%20exam%20prep%20site%20https://exambridge.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-whatsapp fa-2x"></i>
        </a>
      </div>
    </div>
  );
};

export default ShareUs;