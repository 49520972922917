import ShareUs from '../shareUs/ShareUs';
import styles from './Welcome.module.css';

const Welcome = (props) => {    
    return (
      <div className={styles.classes}>
        <header>
          <h2>Welcome to exambridge.com</h2>
        </header>
        <p>
          A free, convenient, and engaging platform designed to help students
          improve their knowledge and confidently prepare for examinations —
          whether for promotion, certification, or admission into tertiary
          institutions.
          <br />
          <strong>Why Choose Us?</strong>
        </p>
        <ul style={{ lineHeight: "2.4rem" }}>
          <li>
            <strong>Unlimited Access: </strong>Explore a vast collection of
            questions, answers, and detailed explanations across various
            subjects.
          </li>
          <li>
            <strong>Expert Content: </strong>All materials are curated and
            reviewed by experienced and qualified tutors.
          </li>
          <li>
            <strong>Flexible Use: </strong> Sign in as a registered member to
            save your progress, or continue as a guest for quick access — no
            registration required.
          </li>
          <li>
            <strong>For Everyone: </strong>Whether you're a student, teacher, or
            parent, this platform is built to support your educational journey.
          </li>
        </ul>
        <p>
          <strong>📚 Subjects We Cover</strong>
          <br />
          We're continually working to expand our resources.
          <br />
          Currently, the following subjects are available:
        </p>
        <ol>
          <li>Mathematics</li>
          <li>Further Mathematics</li>
          <li>Physics</li>
          <li>Biology</li>
          <li>Chemistry</li>
        </ol>
        <p>
          Stay tuned — more subjects will be added soon!
          <br />
          Start exploring now and take your learning to the next level — it's
          all free!
        </p>
        <div style={{ display: "flex", marginLeft: "2rem" }}>
          <p style={{ marginRight: "1rem" }}>
            <strong>Ready to begin?</strong>
          </p>
          <button onClick={props.onDisplayRobotest}>Next</button>
        </div>
        <ShareUs />
      </div>
    );
};

export default Welcome;